.report-track-modal {
    background: linear-gradient(135deg, 
      #e9ecef 0%,#e9ecef 45%,
      #dee2e6 45%, #dee2e6 52%,
      #e9ecef 52%, #e9ecef 58%,
      #dee2e6 58%, #dee2e6 62%,
      #e9ecef 62%, #e9ecef 100%,
      );
    border: 5px #EE2F2C solid;
    border-radius: 50px;
    color: white;
    padding: 20px 30px;
}

.text-container {
    height: 80px;
    border-radius: 20px;
    margin-top: 15px;
    margin-bottom: 30px;
    padding: 8px;
    background-color: white;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: black;
    font-weight: bold;
    align-items: center;
}

.days-left-text {
    color: #FE9923;
}

.hdr-image {
    width: fit-content;
    margin: 0 auto;
    .storier-token-img {
        width: 125px;
    }
    .empty-bank-img {
        width: 150px;
    }
}

.btn-container {
    margin: 0 auto;
}

.btn-use-tokens {
    background-color: #ffbd00;
    color: white;
    margin-right: 10px;
    &:hover {
        background-color: lighten(#ffbd00, 10%);
        color: white;
    }
}

.btn-cancel{
    background-color: #6c757d;
    color: white;
    width: 150px;
    margin: 0 5px;

    &:hover {
        color: white;
        background-color: lighten(#6c757d, 10%);
    }
}

.btn-report {
    background-color: #f32a2d;
    color: white;
    width: 150px;
    margin: 0 5px;

    &:hover {
        color: white;
        background-color: lighten(#f32a2d, 10%);
    }
}