#navigation {
  position: fixed;
  left: 0;
  width: 100%;

  @include respond-below(md) {
    bottom: 0;
    height: $footer-mobile-height !important;;
  }

  @include respond-above(md) {
    top: 0;
    height: calc(100vh - #{$topbar-height});
    width: $sidebar-width;
    padding-bottom: $footer-height;
  }

  .list-group-item {
    border: none;
    background-color: transparent;
    border-radius: 0;
  }

  @include respond-above(md) {
    .nav-icon {
      min-width: 1.5rem;
      font-size: 0.875em;
    }

    // #navs a {
    //   &:focus {
    //     // background-color: 
    //   }
    // }
  }

  @include respond-below(md) {
    display: flex;
    align-self: stretch;
    justify-content: center;
    width: 100%;

    #navs {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      > .list-group-item {
        text-align: center;
      }
    }

    .nav-icon {
      font-size: 1.5em;
    }

    .nav-label {
      display: none;
    }
  }
}
