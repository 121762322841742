// @import "vendor/front/css/theme.css";
@import "vendor/front/scss/theme.scss";
@import "vendor/fontawesome/css/all.css";

@import "util/responsive";
@import "layout/index";

@import "components/spinner";

// @import url("https://use.typekit.net/diz4ocf.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Leckerli+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300");

body {
  font-family: "Montserrat", sans-serif;
  overscroll-behavior: none;
  --connected-color: #09a5be;
  --disconnected-color: #fff;
}

.heavy-font {
  font-family: "Sniglet", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.text-purple {
  color: #2D1582;
}

.display-font {
  font-family: "Leckerli One", "Montserrat", sans-serif;
  font-weight: normal;
}

.mono-time {
  font-family: "Inconsolata", monospace;
  white-space: nowrap;
}

button.no-style {
  border: none !important;

  &:not([class*="bg-"]) {
    background: transparent !important;
  }

  &:focus {
    outline: none !important;
  }

  &:not([class*="d-"]) {
    display: flex !important;
  }

  align-items: center !important;
  justify-content: center !important;
  box-shadow: none !important;

  &.with-icon {
    padding: 0 !important;
    font-size: 16px;
  }
}

// For centering SVG icons
.svgButton,
.has-svg {
  svg {
    margin: 0 auto;
    display: block;
  }
}

.Toastify__toast--default .Toastify__toast-body {
  color: #222 !important;
}

.row.compact-row {
  margin: 0 -10px;

  & > .col,
  & > [class*="col-"] {
    padding: 0px 10px;
  }
}

#trackDropDown,
.no-caret-fix-margin {
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu {
    margin-top: 0;
  }
}

.z-index-1000 {
  z-index: 1000;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.css-navlink {
  @include respond-above(md) {
    &.navlink-active {
      // background-color: #596d82 !important;
      background-color: #2d1582 !important;
      color: white;
      transition: background-color 0.25s ease-in;
      transition: color 0.05s ease-in;
    }

    &:not(.navlink-active):hover {
      background-color: lighten(#596d82, 45%) !important;
      transition: background-color 0.15s ease-in;
    }
  }
}

// LIMIT TEXT TO A SINGLE LINE
.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Make square div
.make-square {
  position: relative;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .square-content {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.add-text-shadow {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

/* Link Muted */
.link-muted {
  color: #8c98a4;
  border-bottom: 1px dashed #97a4af;
}

.link-muted:hover {
  border-color: #0052ea;
}

.buttery-toast {
  z-index: 2000;
  bottom: 149px;

  @include respond-above(md) {
    bottom: 100px;
  }
  color: "black";
}

.css-track-item {
  background-color: #ffffff;
  &:hover {
    background-color: #f9f9fa !important;
  }
}

.css-series-image {
  width: 100%;
  @include respond-above(md) {
    width: 16rem;
  }
  @include respond-above(lg) {
    width: 18rem;
  }
}

.css-no-padding-below-md {
  @include respond-below(md) {
    margin: 0 -1rem;
  }
}

#tracklist {
  .css-track-item {
    border-bottom: none !important;
  }
}

.css-track-item:hover {
  & .css-track-accordian-toggle {
    display: inline-block !important;
  }
}

.css-history-icon-img {
  height: 5rem;
  max-height: 80%;
}
