/*------------------------------------
  Popover
------------------------------------*/

.popover {
  box-shadow: $popover-box-shadow;
}

.popover-header {
  font-weight: $popover-header-font-weight;
}