/*------------------------------------
  File Attachment Button
------------------------------------*/

.file-attachment-btn {
	position: relative;
  overflow: hidden;
  margin-bottom: 0;

  &-label {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    min-width: 100%;
    min-height: 100%;
    cursor: inherit;
    opacity: 0;
  }
}