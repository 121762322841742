/*------------------------------------
  Avatar Initials
------------------------------------*/

.avatar-initials {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}