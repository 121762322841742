/*------------------------------------
  Avatar Status
------------------------------------*/

.avatar-status {
  position: absolute;
  bottom: -($avatar-status-height / 2.5);
  right: -($avatar-status-width / 2.5);
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  vertical-align: baseline;
  border: $avatar-status-border-width $avatar-status-border-type $avatar-status-border-color;
  width: $avatar-status-width;
  height: $avatar-status-height;
  line-height: $avatar-status-line-height;
  font-size: $avatar-status-font-size;
  @include border-radius($avatar-status-border-radius);
}

.avatar-sm-status {
  bottom: -($avatar-status-height / 5);
  right: -($avatar-status-width / 5);
  width: $avatar-status-width-sm;
  height: $avatar-status-height-sm;
  font-size: $avatar-status-font-size-sm;
}

.avatar-lg-status {
  width: $avatar-status-width-lg;
  height: $avatar-status-height-lg;
  font-size: $avatar-status-font-size-lg;
}