/*------------------------------------
  Video Player
------------------------------------*/

.video-player {
  position: relative;

  &-preview {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 1;
    object-fit: cover;
    transition: $video-player-preview-transition;

    .video-player-played & {
      opacity: 0;
      pointer-events: none;
    }
  }

  &-btn {
    z-index: 3;
    transition: $video-player-btn-transition;

    .video-player-played & {
      animation: $video-player-btn-played-animateion;
      pointer-events: none;
    }

    &:hover {
      .video-player-icon {
        color: $video-player-icon-hover-color;
        transform: scale(1.1);
      }
    }
  }

  &-icon {
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    width: $video-player-icon-width;
    height: $video-player-icon-height;
    font-size: $video-player-icon-font-size;
    @include border-radius($video-player-icon-border-radius);
    color: $video-player-icon-color;
    background-color: $video-player-icon-bg-color;
    transition: $video-player-icon-transition;
  }
}

/* Positions */
.video-player-centered {
  @include content-centered;
}

@keyframes videoPlayerButton {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.3);
  }
}