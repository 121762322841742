/*------------------------------------
  Header Fullscreen Style
------------------------------------*/

.fullscreen {
	// Nav
	&-nav {
		padding-left: 0;
	  list-style: none;
	  margin-bottom: 0;

		&-list {
		  list-style: none;
	  	padding-left: 0;
		  margin-bottom: 0;
		}

	  &-link {
	  	display: inline-block;
	  	font-size: $fullscreen-nav-link-font-size;
  		color: $fullscreen-nav-link-color;
			padding-top: $fullscreen-nav-link-padding-y;
			padding-bottom: $fullscreen-nav-link-padding-y;

			// Large Devices
			@include media-breakpoint-up(lg) {
	  		font-size: $fullscreen-nav-link-md-up-font-size;
			}

	  	&:hover {
	  		color: $fullscreen-nav-link-hover-color;
	  	}
	  }
	}

	// Sub Menu
	&-submenu {
	  position: relative;
		transition: $fullscreen-submenu-transition;

		.fullscreen-submenu {
			.fullscreen-submenu-list {
				margin-left: $fullscreen-submenu-list-margin-x;
			}
		}

		.fullscreen-nav-list {
			border-left: $fullscreen-submenu-nav-list-border-left-width $fullscreen-submenu-nav-list-border-left-type $fullscreen-submenu-nav-list-border-left-color;
			padding-left: 0;
		}

		&-nav-link {
			display: inline-block;
  		color: $fullscreen-nav-link-color;
			padding-top: $fullscreen-nav-link-padding-y;
			padding-bottom: $fullscreen-nav-link-padding-y;
			padding-right: $fullscreen-nav-link-padding-x;
			padding-left: $fullscreen-nav-link-padding-x;

			&.disabled {
				color: $fullscreen-nav-link-disabled-color;
				pointer-events: none;
			}

	  	&:hover {
	  		color: $fullscreen-nav-link-hover-color;
	  	}
	  }

		&-list {
		  list-style: none;
			border-left: $fullscreen-submenu-nav-list-border-left-width $fullscreen-submenu-nav-list-border-left-type $fullscreen-submenu-nav-list-border-left-color;
			padding-left: 0;
		  margin-bottom: 0;

			&-link {
		  	display: inline-block;
	  		color: $fullscreen-submenu-list-link-color;

		  	&:hover {
	  			color: $fullscreen-submenu-list-link-hover-color;
		  	}
		  }
		}
  }
}