/*------------------------------------
  Text Colors
------------------------------------*/

.text-white-70 {
	color: $white-color-70;

	&[href]:hover {
		color: $white-color-hover;
	}
}

a:hover {
  .text-hover-primary {
    color: $link-hover-color !important;
  }
}