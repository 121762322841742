.coverArt {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-color: #f8fafd;

  @media (min-width: 576px) {
    border-radius: 0.5rem;
  }
}

.coverArt:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.coverArt > img {
  position: absolute;
  height: 100%;
  z-index: -1;
}