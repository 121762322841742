//
// Buttons
//

// Soft Buttons
@mixin button-soft($hs-color, $hs-background, $hs-hover-background, $hs-active-background, $hs-box-shadow) {
  color: ($hs-color);
  background-color: rgba($hs-background, .1);
  border-color: transparent;

  &:hover,
  &:focus,
  &:active,
  &.active {
    color: color-yiq($hs-hover-background);
    background-color: ($hs-hover-background);
    box-shadow: $btn-box-shadow-value rgba($hs-background, 0.1);
  }

  &.disabled,
  &:disabled {
    color: $hs-background;
    background-color: rgba($hs-background, .1);
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($hs-active-background);
    background-color: $hs-active-background;
  }
}

// Ghost Buttons
@mixin button-ghost($hs-color, $hs-hover-background) {
  color: ($hs-color);
  background-color: $btn-ghost-bg-color;

  &:hover,
  &:focus,
  &:active,
  &.active {
    color: ($hs-color);
    background-color: rgba($hs-hover-background, .1);
  }
}

// Custom Toggle
@mixin button-custom-toggle($hs-hover-background-color, $hs-hover-border-color, $hs-active-background-color, $hs-active-border-color) {
  color: $btn-custom-color;
  border-color: $btn-custom-border-color;

  &:hover {
    color: ($hs-hover-background-color);
    background-color: transparent;
    border-color: ($hs-hover-border-color);
  }

  &:not(:disabled):not(.disabled),
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: color-yiq($hs-active-background-color);
      background-color: ($hs-active-background-color);
      border-color: ($hs-active-border-color);
    }
  }
}