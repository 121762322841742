/*------------------------------------
  Form
------------------------------------*/

.input-group {
  .input-group-prepend,
  .input-group-append {
    z-index: $input-addon-z-index;
  }
}

.custom-select:focus,
.form-control:focus {
  box-shadow: $input-focus-box-shadow;
}

.input-group-text {
  border-color: $input-group-text-border-color;
}

/* Input Label */
.input-label {
  display: block;
  color: $input-label-color;
  font-size: $input-label-font-size;
}

/* Form Borderless */
.input-group-borderless {
  .form-control,
  .input-group-text {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }
}