#top-bar {
  height: $topbar-height;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;

  #logo {
    width: $sidebar-width;
    text-align: center;
    img {
      width: 110px;
      margin-bottom: 5px;
    }
  }
  
  .topbar_right {
    margin-left: auto;
  }

  @include respond-below(md) {
    // position: relative;
    #logo {
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
    }
    .topbar_right {
      position: absolute;
      right: 0;
    }
  }
}
