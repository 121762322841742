#token-banner {
  background: linear-gradient(180deg, #8EE4FF 0%, white 100%);
}

.token-banner-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 10px 20px 20px 20px;
  
  color: #29167D;
  font-weight: bold;
  font-size: 24px;
  font-family: 'Calibri';
}

.progress-outer {
  height: 14px;
  border-radius: 15px;
  background-color: #6D747C;
}

.progress-inner {
  height: 100%;
  width: 50%;
  border-radius: 15px;
  background-color: #FFBD00;
}

