@import url('https://fonts.googleapis.com/css2?family=Sniglet&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,700;1,300;1,700&display=swap');


main#app {
  min-height: 100vh;
  padding-bottom: 200px;
}

#navBottom .nav-link {
  color: white !important;
}

button.no-style {
  border: none;
  background: transparent;
  text-align: left;
  &:focus {
    outline: none !important;
  }
}

.btn-main {
  background-color: #42B0D2;
  border-radius: 50px;
  color: white;

  &:hover {
    background-color: lighten(#42B0D2, 10%);
    color: white;
  }
}

.scss-pb-bw {
  border-width: 4px !important;
}

.single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.full-width-image {
  width: 100vw;
  position: relative;
  left: 50%;
  margin-left: -50vw;
  img {
    width: 100%;
  }
}
