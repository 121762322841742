/*------------------------------------
  Modal
------------------------------------*/

.modal-header {
  align-items: center;
}

.modal-footer {
  padding: $modal-footer-padding-y $modal-footer-padding-x;
}