/*------------------------------------
  List Article
------------------------------------*/

.list-article {
  > li:not(:last-child) {
    padding-bottom: $list-article-padding-y;
  }

  .list-article {
    > li {
      &:first-child {
        padding-top: $list-article-padding-y;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.list-sm-article {
  > li:not(:last-child) {
    padding-bottom: $list-article-padding-y / 2;
  }

  .list-sm-article {
    > li {
      &:first-child {
        padding-top: $list-article-padding-y / 2;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.list-lg-article {
  > li:not(:last-child) {
    padding-bottom: $list-article-padding-y * 2;
  }

  .list-lg-article {
    > li {
      &:first-child {
        padding-top: $list-article-padding-y * 2;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}