#fullScreenPlayer {
  position: fixed;
  z-index: 200;
  height: 100vh;
  width: 100%;
  // background-color: #000000;
  // color: #ffffff;

  h1 {
    color: #ffffff;
  }
}