#player-bar {
  z-index: 10;
  // opacity: 0.8;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $footer-height;

  @include respond-below(md) {
    bottom: $footer-mobile-height;
    height: auto;
    padding: 10px 0;

    .playerbar_secondarycontrols_desktop,
    .playerbar_controls_desktop {
      display: none !important;
    }
  }
}

.playerbar_trackinfo {
  width: 50%;

  .css-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include respond-above(md) {
    width: calc(#{100%} - #{132px});
  }

  @include respond-below(xl) {
    font-size: 0.8rem;
  }
}

#play-pause-square {
  position: absolute;
  height: 64px;
  width: 64px;
  background-color: rgba(0, 0, 0, 0.5);
}

#album-info {
  @include respond-above(md) {
    max-width: 380px;
  }
}

.player-thumbnail {
  height: 64px;
  width: 64px;
}