/*------------------------------------
  Checkbox Icon
------------------------------------*/

.checkbox-icon {
  &-label::after {
    position: absolute;
    top: $checkbox-icon-label-psuedo-top-offset;
    right: $checkbox-icon-label-psuedo-right-offset;
    display: none;
    color: $checkbox-icon-label-psuedo-color;
    font-family: $checkbox-icon-label-psuedo-font-family;
    font-weight: 900;
    line-height: normal;
    content: $checkbox-icon-label-psuedo-content;
  }

  &-input:checked ~ &-label {
    &::after {
      display: block;
      border-color: $checkbox-outline-input-checked-border-color;
    }
  }
}