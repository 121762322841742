/*------------------------------------
  Soft Button Styles
------------------------------------*/

@each $color, $value in $theme-colors {
  /* #{$color} soft */
  .btn-soft-#{$color} {
    @include button-soft($value, $value, $value, $value, $value);
  }
}

.btn-outline-secondary {
  border-color: $btn-outline-secondary-color;
}