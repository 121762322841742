/*------------------------------------
  Sidebar Navigation
------------------------------------*/

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: $sidebar-z-index;
  width: $sidebar-width;
  height: 100%;
  background-color: $sidebar-bg-color;
  box-shadow: $sidebar-right-box-shadow;

  &-body,
  &-content {
    height: 100%;
  }

  &-left {
    right: auto;
    left: 0;
    box-shadow: $sidebar-left-box-shadow;
  }

  &-scroller {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }

  &-container {
    position: relative;
    height: 100%;
    min-height: 100%;
  }

  &-footer {
    width: 100%;
  	position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    &-offset {
      height: 100%;
      box-sizing: border-box;
    }
  }
}