/*------------------------------------
  Custom Buttons
------------------------------------*/

@each $color, $value in $theme-colors {
	/* #{$color} button :hover, :focus, :active effects */
  .btn-#{$color} {
    &[href],
    &[type] {
      &:hover,
      &:focus,
      &:active {
        box-shadow: $btn-box-shadow-value rgba($value, 0.35);
      }
    }

    &:not(label.btn),
    &:not([href]) {
      &,
      &:not([href]):not(:disabled):not(.disabled) {
        background-color: $value;
        border-color: $value;
      }
    }
  }

  .btn-#{$color}:hover {
    background-color: $value;
    border-color: $value;
  }
}