/*------------------------------------
  Z-Index
------------------------------------*/

.z-index-n1 {
	z-index: -1;
}

.z-index-2 {
	z-index: 2;
}

.z-index-4 {
  z-index: 4;
}

.z-index-999 {
  z-index: 999;
}