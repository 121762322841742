#content-panel {
  width: 100%;

  @include respond-above(md) {
    width: 100%;
    margin-left: auto;
  }

  @include respond-below(md) {
    padding-top: $topbar-height;
    padding-bottom: $footer-height + $floating-player-height + 18px;
  }
  padding-bottom: $footer-height;

  > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
