
.nav-link {
    margin-top: 5px;
}

.nav .nav-link:not(.active):hover {
    color: #EE2F2C;
}

.nav:not(.nav-pills) .nav-link.active {
    font-weight: bold;
    color: #EE2F2C;
}