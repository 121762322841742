/*------------------------------------
  Slick Pagination
------------------------------------*/

.slick-pagination {
  padding-left: 0;
  margin-bottom: 0;

  &:not(.slick-pagination-vertical) {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }

  li {
    display: flex;
    pointer-events: all;
    margin: 0 .25rem;
    cursor: pointer;

	  span {
	  	display: inline-block;
	  	width: $slick-pagination-dot-width;
	  	height: $slick-pagination-dot-height;
      box-sizing: border-box;
	    background-color: $slick-pagination-dot-bg-color;
	    border: $slick-pagination-dot-border-width $slick-pagination-dot-border-type $slick-pagination-dot-border-color;
	    @include border-radius($slick-pagination-dot-border-radius);
	    transform: $slick-pagination-dot-transform;
	    transition: $slick-pagination-dot-transition;
	  }

    &.slick-active {
      span {
        background-color: $slick-pagination-dot-bg-active-color;
		    border-color: $slick-pagination-dot-border-active-color;
	    	transform: $slick-pagination-dot-transform-active;
      }
    }
  }
}

/* White Version */
.slick-pagination-white {
  li {
    span {
      background-color: $slick-pagination-white-dot-bg-color;
    }

    &.slick-active {
      span {
        border-color: $slick-pagination-white-dot-border-color;
      }
    }
  }
}

.slick-pagination-vertical {
  li {
    display: block;
  }
}