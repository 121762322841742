/*------------------------------------
  Absolute Positions
------------------------------------*/

.header-abs {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      &-top#{$infix} {
        position: absolute;
        top: 0;
        bottom: auto;
      }
    }
  }
}

// Medium Devices
@include media-breakpoint-down(sm) {
  .header-abs-top-md .header-abs-top-inner {
    max-height: $header-absolute-height;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: $scrollbar-width;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-bg-color;
      visibility: hidden;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
  }
}

// Extra Large Devices
@include media-breakpoint-down(md) {
  .header-abs-top .header-abs-top-inner,
  .header-abs-top-sm .header-abs-top-inner,
  .header-abs-top-lg .header-abs-top-inner {
    max-height: $header-absolute-height;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: $scrollbar-width;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-bg-color;
      visibility: hidden;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
  }
}

// Extra Large Devices
@include media-breakpoint-down(lg) {
  .header-abs-top-xl .header-abs-top-inner {
    max-height: $header-absolute-height;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: $scrollbar-width;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-bg-color;
      visibility: hidden;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
  }
}