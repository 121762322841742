/*------------------------------------
  Content Centered
------------------------------------*/

.content-centered-y {
  @include content-centered(false, true);
}

// Large Devices
@include media-breakpoint-up(md) {
	.content-centered-y-md {
	  @include content-centered(false, true);
	}
}

// Large Devices
@include media-breakpoint-up(lg) {
	.content-centered-y-lg {
	  @include content-centered(false, true);
	}
}