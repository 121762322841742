
.use-tokens-modal {
    background: linear-gradient(135deg, 
        #FFF2BD 0%,#FFF2BD 45%,
        #FFF9E2 45%, #FFF9E2 52%,
        #FFF2BD 52%, #FFF2BD 58%,
        #FFF9E2 58%, #FFF9E2 62%,
        #FFF2BD 62%, #FFF2BD 100%
        );
    border: 5px #FFBD00 solid;
    border-radius: 50px;
    color: white;
    padding: 20px 30px;
}

.use-tokens-text-container {
    height: 125px;
    border-radius: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 8px;
    background-color: white;
    text-align: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    color: black;
    font-weight: bold;
    align-items: center;
}

.days-left-text {
    color: #FE9923;
}

.hdr-image {
    width: fit-content;
    margin: 0 auto;
    .storier-token-img {
        width: 125px;
    }
    .empty-bank-img {
        width: 150px;
    }
}

.btn-container {
    margin: 0 auto;
}

.btn-use-tokens {
    background-color: #ffbd00;
    color: white;
    margin-right: 10px;
    &:hover {
        background-color: lighten(#ffbd00, 10%);
        color: white;
    }
}

.btn-ok {
    background-color: #42B0D2;
    color: white;
    width: 150px;
    margin: 0 5px;

    &:hover {
        color: white;
        background-color: lighten(#42B0D2, 10%);
    }
}

.btn-cancel {
    background-color: #f32a2d;
    color: white;
    width: 150px;
    margin: 0 5px;

    &:hover {
        color: white;
        background-color: lighten(#f32a2d, 10%);
    }
}