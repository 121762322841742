/*------------------------------------
  Button Status
------------------------------------*/

.btn-status {
  position: absolute;
  top: -($btn-status-height / 3);
  right: -($btn-status-width / 3);
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  vertical-align: baseline;
  width: $btn-status-width;
  height: $btn-status-height;
  line-height: $btn-status-line-height;
  font-size: $btn-status-font-size;
  @include border-radius($btn-status-border-radius);

  &-initials {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}