/*------------------------------------
  Slick Equal Height
------------------------------------*/

.slick-equal-height {
	.slick {
		&-list {
			height: 100%;
		}

		&-track {
		  display: flex;
		  height: 100%;

		  .slick-slide {
		  	display: flex;
  			height: auto;
		  }
		}
	}
}