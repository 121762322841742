/*------------------------------------
  Width
------------------------------------*/

.min-w-3rem {
  min-width: 1rem;
}

.min-w-4rem {
  min-width: 1.5rem;
}

.min-w-8rem {
  min-width: 3.5rem;
}

.min-w-21rem {
  min-width: 10rem;
}

.max-w-3rem {
  max-width: 1rem;
}

.max-w-4rem {
  max-width: 1.5rem;
}

.max-w-5rem {
  max-width: 2rem;
}

.max-w-6rem {
  max-width: 2.5rem;
}

.max-w-7rem {
  max-width: 3rem;
}

.max-w-8rem {
  max-width: 3.5rem;
}

.max-w-9rem {
  max-width: 4rem;
}

.max-w-10rem {
  max-width: 4.5rem;
}

.max-w-11rem {
  max-width: 5rem;
}

.max-w-13rem {
  max-width: 6rem;
}

.max-w-15rem {
  max-width: 7rem;
}

.max-w-19rem {
  max-width: 9rem;
}

.max-w-23rem {
  max-width: 11rem;
}

.max-w-27rem {
  max-width: 13rem;
}

.max-w-33rem {
  max-width: 16rem;
}

.max-w-35rem {
  max-width: 17rem;
}

.max-w-40rem {
  max-width: 19.5rem;
}

.max-w-50rem {
  max-width: 24.5rem;
}

/*------------------------------------
  Height
------------------------------------*/

.h-4rem {
  height: .25rem;
}

.h-250rem {
  height: 15.625rem;
}

.h-380rem {
  height: 23.75rem;
}

.min-h-270rem {
  min-height: 16.875rem;
}

.min-h-300rem {
  min-height: 18.75rem;
}

.min-h-380rem {
  min-height: 23.75rem;
}

.min-h-450rem {
  min-height: 28.125rem;
}

.min-h-500rem {
  min-height: 31.25rem;
}

.min-h-620rem {
  min-height: 38.75rem;
}

// Small Devices
@include media-breakpoint-up(sm) {
  .max-w-sm-15rem {
    max-width: 7rem;
  }

  .max-w-sm-40rem {
    max-width: 19.5rem;
  }
}

// Medium Devices
@include media-breakpoint-up(md) {
  .vh-md-30 {
    height: 30vh;
  }

  .vh-md-70 {
    height: 70vh;
  }

  .min-vh-md-100 {
    min-height: 100vh;
  }

  .max-w-md-13rem {
    max-width: 6rem;
  }
}

// Large Devices
@include media-breakpoint-up(lg) {
  .vh-lg-100 {
    height: 100vh;
  }

  .min-vh-lg-100 {
    min-height: 100vh;
  }

  .min-h-lg-600rem {
    min-height: 37.5rem;
  }
}