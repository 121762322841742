.cardLabel {
  @media (max-width: 576px) {
    font-size: 1rem;
  }

  @media (min-width: 576px) {
    font-size: 1rem;
  }
}

.cardSublabel {
  @media (max-width:992px) {
    display: none !important;
  }

  @media (min-width: 992px) {
    font-size: 0.65rem;
  }
}

.iconCard:not(.myLibrary) {
  @media (max-width: 576px) {
    img {
      height: 4rem;
    }
  }

  @media (min-width: 576px) and (max-width: 992px) {
    img {
      display: none !important;
    }
  }
}

.homeCard {

  @media (max-width: 575px) {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
  }

  @media (min-width: 576px) {
    margin-bottom: 1rem;
    padding-right: 0;
    max-width: 300px;
  }
}

.iconCard {
  margin-bottom: 1rem;
  padding-right: 0;
  max-width: 250px;
}


.cardWrapper {
  margin-right: 0px;
}
